// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from './firebase';
import { collection, onSnapshot, query, where, doc, orderBy } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { startOfDay, endOfDay } from 'date-fns';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children, variavel, onVariavelChange }) => {
  const navigate  = useNavigate();
  const empresa = variavel
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    // Chama a função de callback sempre que a variável muda
    onVariavelChange(variavel);
  }, [variavel, onVariavelChange]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      
    });
    return () => unsubscribe();
  }, []);


  const [ordersCollection, setOrdersCollection] = useState([]);

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = startOrdersListener(setOrdersCollection);
      return () => unsubscribe();
    } 
  }, [currentUser]);
  
  const startOrdersListener = (setOrdersCollection) => {
    const todayStart = startOfDay(new Date());
    const todayEnd = endOfDay(new Date());
  
    const ordersListener = onSnapshot(
      query(
        collection(db, "orders"),
        where("customer.uid", "==", currentUser.uid),
        where("order.orderDate", ">=", todayStart),
        where("order.orderDate", "<=", todayEnd)
      ),
      (snapshot) => {
        const ordersData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setOrdersCollection(ordersData);
      });
    return ordersListener;
  };


  const [userCollection, setuserCollection] = useState();

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = startuserListener(setuserCollection);
      return () => unsubscribe();
    } 

  }, [currentUser]);

  const startuserListener = (setuserCollection) => {
    const userRef = doc(db, "users", currentUser.uid);
    
    const userListener = onSnapshot(userRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        // Se o documento existir, atualize setuserCollection com os dados do documento
        setuserCollection({
          id: docSnapshot.id,
          ...docSnapshot.data(),
        });
      } else {
        // Se o documento não existir, defina setuserCollection como null ou um valor padrão, conforme necessário
        setuserCollection(null);
      }
    });

    return userListener;
  };

  const [itemsCollection, setItemsCollection] = useState([]);

  useEffect(() => {
    if (variavel) {
      const unsubscribe = startitemsListener(setItemsCollection);
      return () => unsubscribe();
    } 
  }, [variavel]);

  const startitemsListener = (setItemsCollection) => {
    const itemsListener = onSnapshot(
      query(collection(db, "items"), where("uid", "==", empresa)),
      (snapshot) => {
        const itemsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setItemsCollection(itemsData);
      });
    return itemsListener;
  };

  const [companiesCollection, setCompaniesCollection] = useState();

  useEffect(() => {
    if (variavel) {
      const unsubscribe = startcompaniesListener(setCompaniesCollection);
      return () => unsubscribe();
    } 

  }, [variavel]);

  const startcompaniesListener = (setCompaniesCollection) => {
    const companiesRef = doc(db, "companies", empresa);

    const companiesListener = onSnapshot(companiesRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        // Se o documento existir, atualize setCompaniesCollection com os dados do documento
        setCompaniesCollection({
          id: docSnapshot.id,
          ...docSnapshot.data(),
        });
      } else {
        // Se o documento não existir, defina setCompaniesCollection como null ou um valor padrão, conforme necessário
        setCompaniesCollection(null);
        navigate('/inicial');
      }
    });

    return companiesListener;
  };


  const [addressesCollection, setaddressesCollection] = useState([]);

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = startaddressesListener(setaddressesCollection);
      return () => unsubscribe();
    } 
  }, [currentUser]);

  const startaddressesListener = (setaddressesCollection, onDataUpdateCallback = null) => {
    const addressesListener = onSnapshot(
      query(
        collection(db, "addresses"),
        where("uid", "==", currentUser.uid),
        orderBy('date', 'desc')
      ),
      (snapshot) => {
        const addressesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setaddressesCollection(addressesData);

        // Chama o callback se fornecido
        if (onDataUpdateCallback) {
          onDataUpdateCallback(addressesData);
        }
      },
      (error) => {
        console.error("Error in onSnapshot:", error);
      }
    );
    return addressesListener;
  };

  const [campanies2Collection, setcampanies2Collection] = useState([]);

useEffect(() => {
  const unsubscribe = startcampanies2Listener(setcampanies2Collection);
  return () => unsubscribe();
}, []); // Adicione o array de dependências vazio aqui

const startcampanies2Listener = (setcampanies2Collection, onDataUpdateCallback = null) => {
  const campaniesListener = onSnapshot(collection(db, 'companies'), (snapshot) => {
    const campaniesData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setcampanies2Collection(campaniesData);

    // Chama o callback se fornecido
    if (onDataUpdateCallback) {
      onDataUpdateCallback(campaniesData);
    }
  },
  (error) => {
    console.error("Error in onSnapshot:", error);
  });
  return campaniesListener;
};

  const [configurationsCollection, setconfigurationsCollection] = useState();

  useEffect(() => {
    if (variavel) {
      const unsubscribe = startconfigurationsListener(setconfigurationsCollection);
      return () => unsubscribe();
    } 
  }, [variavel]);

  const startconfigurationsListener = (setconfigurationsCollection) => {
    const configurationsRef = doc(db, "configurations", empresa);

    const configurationsListener = onSnapshot(configurationsRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        // Se o documento existir, atualize setconfigurationsCollection com os dados do documento
        setconfigurationsCollection({
          id: docSnapshot.id,
          ...docSnapshot.data(),
        });
      } else {
        // Se o documento não existir, defina setconfigurationsCollection como null ou um valor padrão, conforme necessário
        setconfigurationsCollection(null);
      }
    });

    return configurationsListener;
  };

  const [categoriasCollection, setCategoriasCollection] = useState([]);

  useEffect(() => {
    if (variavel) {
      const unsubscribe = startcategoriasListener(setCategoriasCollection);
      return () => unsubscribe();
    } 
  }, [variavel]);

  const startcategoriasListener = (setCategoriasCollection) => {
    const categoriasListener = onSnapshot(
      query(collection(db, "categorias"), where("uid", "==", empresa)),
      (snapshot) => {
        const categoriasData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategoriasCollection(categoriasData);
      });
    return categoriasListener;
  };

  const [businessCategoriesCollection, setbusinessCategoriesCollection] = useState([]);

  useEffect(() => {
      const unsubscribe = startbusinessCategoriesListener(setbusinessCategoriesCollection);
      return () => unsubscribe();
  }, []);

  const startbusinessCategoriesListener = (setbusinessCategoriesCollection) => {
    const businessCategoriesListener = onSnapshot(
      collection(db, "businessCategories"),
      (snapshot) => {
        const businessCategoriesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setbusinessCategoriesCollection(businessCategoriesData);
      });
    return businessCategoriesListener;
  };

  const [featuredCategoriesCollection, setfeaturedCategoriesCollection] = useState([]);

  useEffect(() => {
      const unsubscribe = startfeaturedCategoriesListener(setfeaturedCategoriesCollection);
      return () => unsubscribe();
  }, []);

  const startfeaturedCategoriesListener = (setfeaturedCategoriesCollection) => {
    const featuredCategoriesListener = onSnapshot(
      collection(db, "featuredCategories"),
      (snapshot) => {
        const featuredCategoriesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setfeaturedCategoriesCollection(featuredCategoriesData);
      });
    return featuredCategoriesListener;
  };


  const [offersCategoriesCollection, setoffersCategoriesCollection] = useState([]);

  useEffect(() => {
      const unsubscribe = startoffersCategoriesListener(setoffersCategoriesCollection);
      return () => unsubscribe();
  }, []);

  const startoffersCategoriesListener = (setoffersCategoriesCollection) => {
    const offersCategoriesListener = onSnapshot(
      collection(db, "offersCategories"),
      (snapshot) => {
        const offersCategoriesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setoffersCategoriesCollection(offersCategoriesData);
      });
    return offersCategoriesListener;
  };

  const [statusGeral, setStatusGeral] = useState(null);

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = startStatusGeralListener(setStatusGeral);
      return () => unsubscribe();
    }
  }, [currentUser]);
  
  const startStatusGeralListener = (setStatusGeral ) => {
  
     //const documentoRef = doc(db, 'statusGeral2', 'HoX5CZjstwi1RnArV6bZ');
     const documentoRef = doc(db, 'statusGeral', '7aR3cmTSMUYkM9mcIRaD');
  
    const statusGeralListener = onSnapshot(documentoRef, (doc) => {
      if (doc.exists()) {
        setStatusGeral(doc.data());
      } else {
        console.log("Documento não encontrado");
      }
    }, (error) => {
      console.error("Erro em onSnapshot:", error);
    });
  
    return statusGeralListener;
  };

  const value = {
    currentUser,
    userCollection,
    ordersCollection,
    itemsCollection,
    companiesCollection,
    campanies2Collection,
    categoriasCollection,
    addressesCollection,
    startcategoriasListener,
    configurationsCollection,
    businessCategoriesCollection,
    featuredCategoriesCollection,
    offersCategoriesCollection,
    statusGeral,
  };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
